/* latin-ext */
@font-face {
  font-family: '__Poppins_12c8de';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0a03a6d30c07af2e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_12c8de';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_12c8de';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_12c8de {font-family: '__Poppins_12c8de', '__Poppins_Fallback_12c8de';font-weight: 600;font-style: normal
}.__variable_12c8de {--font-poppins: '__Poppins_12c8de', '__Poppins_Fallback_12c8de'
}

@media (prefers-color-scheme: dark) {
  .wmde-markdown,
  .wmde-markdown-var {
    color-scheme: dark;
    --color-prettylights-syntax-comment: #8b949e;
    --color-prettylights-syntax-constant: #79c0ff;
    --color-prettylights-syntax-entity: #d2a8ff;
    --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
    --color-prettylights-syntax-entity-tag: #7ee787;
    --color-prettylights-syntax-keyword: #ff7b72;
    --color-prettylights-syntax-string: #a5d6ff;
    --color-prettylights-syntax-variable: #ffa657;
    --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
    --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
    --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
    --color-prettylights-syntax-carriage-return-text: #f0f6fc;
    --color-prettylights-syntax-carriage-return-bg: #b62324;
    --color-prettylights-syntax-string-regexp: #7ee787;
    --color-prettylights-syntax-markup-list: #f2cc60;
    --color-prettylights-syntax-markup-heading: #1f6feb;
    --color-prettylights-syntax-markup-italic: #c9d1d9;
    --color-prettylights-syntax-markup-bold: #c9d1d9;
    --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
    --color-prettylights-syntax-markup-deleted-bg: #67060c;
    --color-prettylights-syntax-markup-inserted-text: #aff5b4;
    --color-prettylights-syntax-markup-inserted-bg: #033a16;
    --color-prettylights-syntax-markup-changed-text: #ffdfb6;
    --color-prettylights-syntax-markup-changed-bg: #5a1e02;
    --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
    --color-prettylights-syntax-markup-ignored-bg: #1158c7;
    --color-prettylights-syntax-meta-diff-range: #d2a8ff;
    --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
    --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
    --color-fg-default: #c9d1d9;
    --color-fg-muted: #8b949e;
    --color-fg-subtle: #484f58;
    --color-canvas-default: #0d1117;
    --color-canvas-subtle: #161b22;
    --color-border-default: #30363d;
    --color-border-muted: #21262d;
    --color-neutral-muted: rgba(110, 118, 129, 0.4);
    --color-accent-fg: #58a6ff;
    --color-accent-emphasis: #1f6feb;
    --color-attention-subtle: rgba(187, 128, 9, 0.15);
    --color-danger-fg: #f85149;
  }
}
@media (prefers-color-scheme: light) {
  .wmde-markdown,
  .wmde-markdown-var {
    color-scheme: light;
    --color-prettylights-syntax-comment: #6e7781;
    --color-prettylights-syntax-constant: #0550ae;
    --color-prettylights-syntax-entity: #8250df;
    --color-prettylights-syntax-storage-modifier-import: #24292f;
    --color-prettylights-syntax-entity-tag: #116329;
    --color-prettylights-syntax-keyword: #cf222e;
    --color-prettylights-syntax-string: #0a3069;
    --color-prettylights-syntax-variable: #953800;
    --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
    --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
    --color-prettylights-syntax-invalid-illegal-bg: #82071e;
    --color-prettylights-syntax-carriage-return-text: #f6f8fa;
    --color-prettylights-syntax-carriage-return-bg: #cf222e;
    --color-prettylights-syntax-string-regexp: #116329;
    --color-prettylights-syntax-markup-list: #3b2300;
    --color-prettylights-syntax-markup-heading: #0550ae;
    --color-prettylights-syntax-markup-italic: #24292f;
    --color-prettylights-syntax-markup-bold: #24292f;
    --color-prettylights-syntax-markup-deleted-text: #82071e;
    --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
    --color-prettylights-syntax-markup-inserted-text: #116329;
    --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
    --color-prettylights-syntax-markup-changed-text: #953800;
    --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
    --color-prettylights-syntax-markup-ignored-text: #eaeef2;
    --color-prettylights-syntax-markup-ignored-bg: #0550ae;
    --color-prettylights-syntax-meta-diff-range: #8250df;
    --color-prettylights-syntax-brackethighlighter-angle: #57606a;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
    --color-prettylights-syntax-constant-other-reference-link: #0a3069;
    --color-fg-default: #24292f;
    --color-fg-muted: #57606a;
    --color-fg-subtle: #6e7781;
    --color-canvas-default: #ffffff;
    --color-canvas-subtle: #f6f8fa;
    --color-border-default: #d0d7de;
    --color-border-muted: hsl(210, 18%, 87%);
    --color-neutral-muted: rgba(175, 184, 193, 0.2);
    --color-accent-fg: #0969da;
    --color-accent-emphasis: #0969da;
    --color-attention-subtle: #fff8c5;
    --color-danger-fg: #cf222e;
  }
}
[data-color-mode*='dark'] .wmde-markdown,
[data-color-mode*='dark'] .wmde-markdown-var,
.wmde-markdown-var[data-color-mode*='dark'],
.wmde-markdown[data-color-mode*='dark'],
body[data-color-mode*='dark'] {
  color-scheme: dark;
  --color-prettylights-syntax-comment: #8b949e;
  --color-prettylights-syntax-constant: #79c0ff;
  --color-prettylights-syntax-entity: #d2a8ff;
  --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
  --color-prettylights-syntax-entity-tag: #7ee787;
  --color-prettylights-syntax-keyword: #ff7b72;
  --color-prettylights-syntax-string: #a5d6ff;
  --color-prettylights-syntax-variable: #ffa657;
  --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
  --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
  --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
  --color-prettylights-syntax-carriage-return-text: #f0f6fc;
  --color-prettylights-syntax-carriage-return-bg: #b62324;
  --color-prettylights-syntax-string-regexp: #7ee787;
  --color-prettylights-syntax-markup-list: #f2cc60;
  --color-prettylights-syntax-markup-heading: #1f6feb;
  --color-prettylights-syntax-markup-italic: #c9d1d9;
  --color-prettylights-syntax-markup-bold: #c9d1d9;
  --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
  --color-prettylights-syntax-markup-deleted-bg: #67060c;
  --color-prettylights-syntax-markup-inserted-text: #aff5b4;
  --color-prettylights-syntax-markup-inserted-bg: #033a16;
  --color-prettylights-syntax-markup-changed-text: #ffdfb6;
  --color-prettylights-syntax-markup-changed-bg: #5a1e02;
  --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
  --color-prettylights-syntax-markup-ignored-bg: #1158c7;
  --color-prettylights-syntax-meta-diff-range: #d2a8ff;
  --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
  --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
  --color-fg-default: #c9d1d9;
  --color-fg-muted: #8b949e;
  --color-fg-subtle: #484f58;
  --color-canvas-default: #0d1117;
  --color-canvas-subtle: #161b22;
  --color-border-default: #30363d;
  --color-border-muted: #21262d;
  --color-neutral-muted: rgba(110, 118, 129, 0.4);
  --color-accent-fg: #58a6ff;
  --color-accent-emphasis: #1f6feb;
  --color-attention-subtle: rgba(187, 128, 9, 0.15);
  --color-danger-fg: #f85149;
}
[data-color-mode*='light'] .wmde-markdown,
[data-color-mode*='light'] .wmde-markdown-var,
.wmde-markdown-var[data-color-mode*='light'],
.wmde-markdown[data-color-mode*='light'],
body[data-color-mode*='light'] {
  color-scheme: light;
  --color-prettylights-syntax-comment: #6e7781;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-entity: #8250df;
  --color-prettylights-syntax-storage-modifier-import: #24292f;
  --color-prettylights-syntax-entity-tag: #116329;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-variable: #953800;
  --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
  --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
  --color-prettylights-syntax-invalid-illegal-bg: #82071e;
  --color-prettylights-syntax-carriage-return-text: #f6f8fa;
  --color-prettylights-syntax-carriage-return-bg: #cf222e;
  --color-prettylights-syntax-string-regexp: #116329;
  --color-prettylights-syntax-markup-list: #3b2300;
  --color-prettylights-syntax-markup-heading: #0550ae;
  --color-prettylights-syntax-markup-italic: #24292f;
  --color-prettylights-syntax-markup-bold: #24292f;
  --color-prettylights-syntax-markup-deleted-text: #82071e;
  --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
  --color-prettylights-syntax-markup-inserted-text: #116329;
  --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
  --color-prettylights-syntax-markup-changed-text: #953800;
  --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
  --color-prettylights-syntax-markup-ignored-text: #eaeef2;
  --color-prettylights-syntax-markup-ignored-bg: #0550ae;
  --color-prettylights-syntax-meta-diff-range: #8250df;
  --color-prettylights-syntax-brackethighlighter-angle: #57606a;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
  --color-prettylights-syntax-constant-other-reference-link: #0a3069;
  --color-fg-default: #24292f;
  --color-fg-muted: #57606a;
  --color-fg-subtle: #6e7781;
  --color-canvas-default: #ffffff;
  --color-canvas-subtle: #f6f8fa;
  --color-border-default: #d0d7de;
  --color-border-muted: hsl(210, 18%, 87%);
  --color-neutral-muted: rgba(175, 184, 193, 0.2);
  --color-accent-fg: #0969da;
  --color-accent-emphasis: #0969da;
  --color-attention-subtle: #fff8c5;
  --color-danger-fg: #cf222e;
}
.wmde-markdown {
  -webkit-text-size-adjust: 100%;
  font-family: -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-default);
}
.wmde-markdown details,
.wmde-markdown figcaption,
.wmde-markdown figure {
  display: block;
}
.wmde-markdown summary {
  display: list-item;
}
.wmde-markdown [hidden] {
  display: none !important;
}
.wmde-markdown a {
  background-color: transparent;
  color: var(--color-accent-fg);
  text-decoration: none;
}
.wmde-markdown a:active,
.wmde-markdown a:hover {
  outline-width: 0;
}
.wmde-markdown abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}
.wmde-markdown b,
.wmde-markdown strong {
  font-weight: 600;
}
.wmde-markdown dfn {
  font-style: italic;
}
.wmde-markdown h1 {
  margin: 0.67em 0;
  font-weight: 600;
  padding-bottom: 0.3em;
  font-size: 2em;
  border-bottom: 1px solid var(--color-border-muted);
}
.wmde-markdown mark {
  background-color: var(--color-attention-subtle);
  color: var(--color-text-primary);
}
.wmde-markdown small {
  font-size: 90%;
}
.wmde-markdown sub,
.wmde-markdown sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
.wmde-markdown sub {
  bottom: -0.25em;
}
.wmde-markdown sup {
  top: -0.5em;
}
.wmde-markdown img {
  display: inline-block;
  border-style: none;
  max-width: 100%;
  box-sizing: content-box;
  background-color: var(--color-canvas-default);
}
.wmde-markdown code,
.wmde-markdown kbd,
.wmde-markdown pre,
.wmde-markdown samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
.wmde-markdown figure {
  margin: 1em 40px;
}
.wmde-markdown hr {
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid var(--color-border-muted);
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: var(--color-border-default);
}
.wmde-markdown input {
  font: inherit;
  margin: 0;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.wmde-markdown [type='button'],
.wmde-markdown [type='reset'],
.wmde-markdown [type='submit'] {
  -webkit-appearance: button;
}
.wmde-markdown [type='button']::-moz-focus-inner,
.wmde-markdown [type='reset']::-moz-focus-inner,
.wmde-markdown [type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.wmde-markdown [type='button']:-moz-focusring,
.wmde-markdown [type='reset']:-moz-focusring,
.wmde-markdown [type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
.wmde-markdown [type='checkbox'],
.wmde-markdown [type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
.wmde-markdown [type='number']::-webkit-inner-spin-button,
.wmde-markdown [type='number']::-webkit-outer-spin-button {
  height: auto;
}
.wmde-markdown [type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
.wmde-markdown [type='search']::-webkit-search-cancel-button,
.wmde-markdown [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
.wmde-markdown ::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
.wmde-markdown ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.wmde-markdown a:hover {
  text-decoration: underline;
}
.wmde-markdown hr::before {
  display: table;
  content: '';
}
.wmde-markdown hr::after {
  display: table;
  clear: both;
  content: '';
}
.wmde-markdown table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: max-content;
  max-width: 100%;
}
.wmde-markdown td,
.wmde-markdown th {
  padding: 0;
}
.wmde-markdown details summary {
  cursor: pointer;
}
.wmde-markdown details:not([open]) > *:not(summary) {
  display: none !important;
}
.wmde-markdown kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  line-height: 10px;
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted);
}
.wmde-markdown h1,
.wmde-markdown h2,
.wmde-markdown h3,
.wmde-markdown h4,
.wmde-markdown h5,
.wmde-markdown h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}
.wmde-markdown td,
.wmde-markdown th {
  padding: 0;
}
.wmde-markdown details summary {
  cursor: pointer;
}
.wmde-markdown details:not([open]) > *:not(summary) {
  display: none !important;
}
.wmde-markdown kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  line-height: 10px;
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted);
}
.wmde-markdown h1,
.wmde-markdown h2,
.wmde-markdown h3,
.wmde-markdown h4,
.wmde-markdown h5,
.wmde-markdown h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}
.wmde-markdown h2 {
  font-weight: 600;
  padding-bottom: 0.3em;
  font-size: 1.5em;
  border-bottom: 1px solid var(--color-border-muted);
}
.wmde-markdown h3 {
  font-weight: 600;
  font-size: 1.25em;
}
.wmde-markdown h4 {
  font-weight: 600;
  font-size: 1em;
}
.wmde-markdown h5 {
  font-weight: 600;
  font-size: 0.875em;
}
.wmde-markdown h6 {
  font-weight: 600;
  font-size: 0.85em;
  color: var(--color-fg-muted);
}
.wmde-markdown p {
  margin-top: 0;
  margin-bottom: 10px;
}
.wmde-markdown blockquote {
  margin: 0;
  padding: 0 1em;
  color: var(--color-fg-muted);
  border-left: 0.25em solid var(--color-border-default);
}
.wmde-markdown ul,
.wmde-markdown ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}
.wmde-markdown ol ol,
.wmde-markdown ul ol {
  list-style-type: lower-roman;
}
.wmde-markdown ul ul ol,
.wmde-markdown ul ol ol,
.wmde-markdown ol ul ol,
.wmde-markdown ol ol ol {
  list-style-type: lower-alpha;
}
.wmde-markdown dd {
  margin-left: 0;
}
.wmde-markdown tt,
.wmde-markdown code {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
}
.wmde-markdown pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
  word-wrap: normal;
}
.wmde-markdown .octicon {
  display: inline-block;
  overflow: visible !important;
  vertical-align: text-bottom;
  fill: currentColor;
}
.wmde-markdown ::placeholder {
  color: var(--color-fg-subtle);
  opacity: 1;
}
.wmde-markdown input::-webkit-outer-spin-button,
.wmde-markdown input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}
.wmde-markdown [data-catalyst] {
  display: block;
}
.wmde-markdown::before {
  display: table;
  content: '';
}
.wmde-markdown::after {
  display: table;
  clear: both;
  content: '';
}
.wmde-markdown > *:first-child {
  margin-top: 0 !important;
}
.wmde-markdown > *:last-child {
  margin-bottom: 0 !important;
}
.wmde-markdown a:not([href]) {
  color: inherit;
  text-decoration: none;
}
.wmde-markdown .absent {
  color: var(--color-danger-fg);
}
.wmde-markdown a.anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1;
}
.wmde-markdown .anchor:focus {
  outline: none;
}
.wmde-markdown p,
.wmde-markdown blockquote,
.wmde-markdown ul,
.wmde-markdown ol,
.wmde-markdown dl,
.wmde-markdown table,
.wmde-markdown pre,
.wmde-markdown details {
  margin-top: 0;
  margin-bottom: 16px;
}
.wmde-markdown blockquote > :first-child {
  margin-top: 0;
}
.wmde-markdown blockquote > :last-child {
  margin-bottom: 0;
}
.wmde-markdown sup > a::before {
  content: '[';
}
.wmde-markdown sup > a::after {
  content: ']';
}
.wmde-markdown h1 .octicon-link,
.wmde-markdown h2 .octicon-link,
.wmde-markdown h3 .octicon-link,
.wmde-markdown h4 .octicon-link,
.wmde-markdown h5 .octicon-link,
.wmde-markdown h6 .octicon-link {
  color: var(--color-fg-default);
  vertical-align: middle;
  visibility: hidden;
}
.wmde-markdown h1:hover .anchor,
.wmde-markdown h2:hover .anchor,
.wmde-markdown h3:hover .anchor,
.wmde-markdown h4:hover .anchor,
.wmde-markdown h5:hover .anchor,
.wmde-markdown h6:hover .anchor {
  text-decoration: none;
}
.wmde-markdown h1:hover .anchor .octicon-link,
.wmde-markdown h2:hover .anchor .octicon-link,
.wmde-markdown h3:hover .anchor .octicon-link,
.wmde-markdown h4:hover .anchor .octicon-link,
.wmde-markdown h5:hover .anchor .octicon-link,
.wmde-markdown h6:hover .anchor .octicon-link {
  visibility: visible;
}
.wmde-markdown h1 tt,
.wmde-markdown h1 code,
.wmde-markdown h2 tt,
.wmde-markdown h2 code,
.wmde-markdown h3 tt,
.wmde-markdown h3 code,
.wmde-markdown h4 tt,
.wmde-markdown h4 code,
.wmde-markdown h5 tt,
.wmde-markdown h5 code,
.wmde-markdown h6 tt,
.wmde-markdown h6 code {
  padding: 0 0.2em;
  font-size: inherit;
}
.wmde-markdown ul.no-list,
.wmde-markdown ol.no-list {
  padding: 0;
  list-style-type: none;
}
.wmde-markdown ol[type='1'] {
  list-style-type: decimal;
}
.wmde-markdown ol[type='a'] {
  list-style-type: lower-alpha;
}
.wmde-markdown ol[type='i'] {
  list-style-type: lower-roman;
}
.wmde-markdown div > ol:not([type]) {
  list-style-type: decimal;
}
.wmde-markdown ul ul,
.wmde-markdown ul ol,
.wmde-markdown ol ol,
.wmde-markdown ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
.wmde-markdown li > p {
  margin-top: 16px;
}
.wmde-markdown li + li {
  margin-top: 0.25em;
}
.wmde-markdown dl {
  padding: 0;
}
.wmde-markdown dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}
.wmde-markdown dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}
.wmde-markdown table th {
  font-weight: 600;
}
.wmde-markdown table th,
.wmde-markdown table td {
  padding: 6px 13px;
  border: 1px solid var(--color-border-default);
}
.wmde-markdown table tr {
  background-color: var(--color-canvas-default);
  border-top: 1px solid var(--color-border-muted);
}
.wmde-markdown table tr:nth-child(2n) {
  background-color: var(--color-canvas-subtle);
}
.wmde-markdown table img {
  background-color: transparent;
}
.wmde-markdown img[align='right'] {
  padding-left: 20px;
}
.wmde-markdown img[align='left'] {
  padding-right: 20px;
}
.wmde-markdown .emoji {
  max-width: none;
  vertical-align: text-top;
  background-color: transparent;
}
.wmde-markdown span.frame {
  display: block;
  overflow: hidden;
}
.wmde-markdown span.frame > span {
  display: block;
  float: left;
  width: auto;
  padding: 7px;
  margin: 13px 0 0;
  overflow: hidden;
  border: 1px solid var(--color-border-default);
}
.wmde-markdown span.frame span img {
  display: block;
  float: left;
}
.wmde-markdown span.frame span span {
  display: block;
  padding: 5px 0 0;
  clear: both;
  color: var(--color-fg-default);
}
.wmde-markdown span.align-center {
  display: block;
  overflow: hidden;
  clear: both;
}
.wmde-markdown span.align-center > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: center;
}
.wmde-markdown span.align-center span img {
  margin: 0 auto;
  text-align: center;
}
.wmde-markdown span.align-right {
  display: block;
  overflow: hidden;
  clear: both;
}
.wmde-markdown span.align-right > span {
  display: block;
  margin: 13px 0 0;
  overflow: hidden;
  text-align: right;
}
.wmde-markdown span.align-right span img {
  margin: 0;
  text-align: right;
}
.wmde-markdown span.float-left {
  display: block;
  float: left;
  margin-right: 13px;
  overflow: hidden;
}
.wmde-markdown span.float-left span {
  margin: 13px 0 0;
}
.wmde-markdown span.float-right {
  display: block;
  float: right;
  margin-left: 13px;
  overflow: hidden;
}
.wmde-markdown span.float-right > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: right;
}
.wmde-markdown code,
.wmde-markdown tt {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: var(--color-neutral-muted);
  border-radius: 6px;
}
.wmde-markdown code br,
.wmde-markdown tt br {
  display: none;
}
.wmde-markdown del code {
  text-decoration: inherit;
}
.wmde-markdown pre code {
  font-size: 100%;
}
.wmde-markdown pre > code {
  padding: 0;
  margin: 0;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}
.wmde-markdown pre {
  font-size: 85%;
  line-height: 1.45;
  background-color: var(--color-canvas-subtle);
  border-radius: 6px;
}
.wmde-markdown pre code,
.wmde-markdown pre tt {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}
.wmde-markdown pre > code {
  padding: 16px;
  overflow: auto;
  display: block;
}
.wmde-markdown .csv-data td,
.wmde-markdown .csv-data th {
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  white-space: nowrap;
}
.wmde-markdown .csv-data .blob-num {
  padding: 10px 8px 9px;
  text-align: right;
  background: var(--color-canvas-default);
  border: 0;
}
.wmde-markdown .csv-data tr {
  border-top: 0;
}
.wmde-markdown .csv-data th {
  font-weight: 600;
  background: var(--color-canvas-subtle);
  border-top: 0;
}
.wmde-markdown .footnotes {
  font-size: 12px;
  color: var(--color-fg-muted);
  border-top: 1px solid var(--color-border-default);
}
.wmde-markdown .footnotes ol {
  padding-left: 16px;
}
.wmde-markdown .footnotes li {
  position: relative;
}
.wmde-markdown .footnotes li:target::before {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -24px;
  pointer-events: none;
  content: '';
  border: 2px solid var(--color-accent-emphasis);
  border-radius: 6px;
}
.wmde-markdown .footnotes li:target {
  color: var(--color-fg-default);
}
.wmde-markdown .footnotes .data-footnote-backref g-emoji {
  font-family: monospace;
}
.wmde-markdown .task-list-item {
  list-style-type: none;
}
.wmde-markdown .task-list-item label {
  font-weight: 400;
}
.wmde-markdown .task-list-item.enabled label {
  cursor: pointer;
}
.wmde-markdown .task-list-item + .wmde-markdown .task-list-item {
  margin-top: 3px;
}
.wmde-markdown .task-list-item .handle {
  display: none;
}
.wmde-markdown .task-list-item-checkbox,
.wmde-markdown .contains-task-list input[type='checkbox'] {
  margin: 0 0.2em 0.25em -1.6em;
  vertical-align: middle;
}
.wmde-markdown .contains-task-list:dir(rtl) .task-list-item-checkbox,
.wmde-markdown .contains-task-list:dir(rtl) input[type='checkbox'] {
  margin: 0 -1.6em 0.25em 0.2em;
}
.wmde-markdown ::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}
.wmde-markdown pre {
  position: relative;
}
.wmde-markdown pre .copied {
  visibility: hidden;
  display: flex;
  position: absolute;
  cursor: pointer;
  color: var(--color-fg-defaul);
  top: 6px;
  right: 6px;
  border-radius: 5px;
  background: var(--color-border-default);
  padding: 6px;
  font-size: 12px;
  transition: all 0.3s;
}
.wmde-markdown pre .copied .octicon-copy {
  display: block;
}
.wmde-markdown pre .copied .octicon-check {
  display: none;
}
.wmde-markdown pre:hover .copied {
  visibility: visible;
}
.wmde-markdown pre:hover .copied:hover {
  background: var(--color-prettylights-syntax-entity-tag);
  color: var(--color-canvas-default);
}
.wmde-markdown pre:hover .copied:active,
.wmde-markdown pre .copied.active {
  background: #2e9b33;
  color: var(--color-canvas-default);
}
.wmde-markdown pre .active .octicon-copy {
  display: none;
}
.wmde-markdown pre .active .octicon-check {
  display: block;
}
.highlight-line {
  background-color: var(--color-neutral-muted);
}
.code-line.line-number::before {
  display: inline-block;
  width: 1rem;
  text-align: right;
  margin-right: 16px;
  color: var(--color-fg-subtle);
  content: attr(line);
  white-space: nowrap;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: var(--color-prettylights-syntax-comment);
}
.token.namespace {
  opacity: 0.7;
}
.token.property,
.token.tag,
.token.selector,
.token.constant,
.token.symbol,
.token.deleted {
  color: var(--color-prettylights-syntax-entity-tag);
}
.token.maybe-class-name {
  color: var(--color-prettylights-syntax-variable);
}
.token.property-access,
.token.operator,
.token.boolean,
.token.number,
.token.selector .token.class,
.token.attr-name,
.token.string,
.token.char,
.token.builtin {
  color: var(--color-prettylights-syntax-constant);
}
.token.deleted {
  color: var(--color-prettylights-syntax-markup-deleted-text);
}
.code-line .token.deleted {
  background-color: var(--color-prettylights-syntax-markup-deleted-bg);
}
.token.inserted {
  color: var(--color-prettylights-syntax-markup-inserted-text);
}
.code-line .token.inserted {
  background-color: var(--color-prettylights-syntax-markup-inserted-bg);
}
.token.variable {
  color: var(--color-prettylights-syntax-constant);
}
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: var(--color-prettylights-syntax-string);
}
.token.color,
.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
  color: var(--color-prettylights-syntax-string);
}
.token.rule,
.token.regex,
.token.important,
.token.keyword {
  color: var(--color-prettylights-syntax-keyword);
}
.token.coord {
  color: var(--color-prettylights-syntax-meta-diff-range);
}
.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}

.w-md-editor-bar {
  position: absolute;
  cursor: s-resize;
  right: 0;
  bottom: 0;
  margin-top: -11px;
  margin-right: 0;
  width: 14px;
  z-index: 3;
  height: 10px;
  border-radius: 0 0 3px 0;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
}
.w-md-editor-bar svg {
  display: block;
  margin: 0 auto;
}
.w-md-editor-area {
  overflow: auto;
  border-radius: 5px;
}
.w-md-editor-text {
  min-height: 100%;
  position: relative;
  text-align: left;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  font-size: 14px !important;
  line-height: 18px !important;
  font-feature-settings: "liga", "clig";
  font-variant-ligatures: common-ligatures;
}
.w-md-editor-text-pre,
.w-md-editor-text-input,
.w-md-editor-text > .w-md-editor-text-pre {
  margin: 0;
  border: 0;
  background: none;
  box-sizing: inherit;
  display: inherit;
  font-family: inherit;
  font-family: var(--md-editor-font-family) !important;
  font-size: inherit;
  font-style: inherit;
  font-feature-settings: inherit;
  font-variant-ligatures: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  -moz-tab-size: inherit;
       tab-size: inherit;
  text-indent: inherit;
  text-rendering: inherit;
  text-transform: inherit;
  white-space: inherit;
  overflow-wrap: inherit;
  word-break: inherit;
  word-break: normal;
  padding: 0;
}
.w-md-editor-text-pre {
  position: relative;
  margin: 0px !important;
  pointer-events: none;
  background-color: transparent !important;
}
.w-md-editor-text-pre > code {
  padding: 0 !important;
  font-family: var(--md-editor-font-family) !important;
  font-size: 14px !important;
  line-height: 18px !important;
}
.w-md-editor-text-input {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  resize: none;
  color: inherit;
  overflow: hidden;
  outline: 0;
  padding: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-text-fill-color: transparent;
}
.w-md-editor-text-input:empty {
  -webkit-text-fill-color: inherit !important;
}
.w-md-editor-text-pre,
.w-md-editor-text-input {
  word-wrap: pre;
  word-break: break-word;
  white-space: pre-wrap;
}
/**
 * Hack to apply on some CSS on IE10 and IE11
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /**
    * IE doesn't support '-webkit-text-fill-color'
    * So we use 'color: transparent' to make the text transparent on IE
    * Unlike other browsers, it doesn't affect caret color in IE
    */
  .w-md-editor-text-input {
    color: transparent !important;
  }
  .w-md-editor-text-input::selection {
    background-color: #accef7 !important;
    color: transparent !important;
  }
}
.w-md-editor-text-pre .punctuation {
  color: var(--color-prettylights-syntax-comment, #8b949e) !important;
}
.w-md-editor-text-pre .token.url,
.w-md-editor-text-pre .token.content {
  color: var(--color-prettylights-syntax-constant, #0550ae) !important;
}
.w-md-editor-text-pre .token.title.important {
  color: var(--color-prettylights-syntax-markup-bold, #24292f);
}
.w-md-editor-text-pre .token.code-block .function {
  color: var(--color-prettylights-syntax-entity, #8250df);
}
.w-md-editor-text-pre .token.bold {
  font-weight: unset !important;
}
.w-md-editor-text-pre .token.title {
  line-height: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
}
.w-md-editor-text-pre .token.code.keyword {
  color: var(--color-prettylights-syntax-constant, #0550ae) !important;
}
.w-md-editor-text-pre .token.strike,
.w-md-editor-text-pre .token.strike .content {
  color: var(--color-prettylights-syntax-markup-deleted-text, #82071e) !important;
}
.w-md-editor-toolbar-child {
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 0 0 1px var(--md-editor-box-shadow-color), 0 0 0 var(--md-editor-box-shadow-color), 0 1px 1px var(--md-editor-box-shadow-color);
  background-color: var(--md-editor-background-color);
  z-index: 1;
  display: none;
}
.w-md-editor-toolbar-child.active {
  display: block;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar {
  border-bottom: 0;
  padding: 3px;
  border-radius: 3px;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li {
  display: block;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li button {
  width: -webkit-fill-available;
  height: initial;
  box-sizing: border-box;
  padding: 3px 4px 2px 4px;
  margin: 0;
}
.w-md-editor-toolbar {
  border-bottom: 1px solid var(--md-editor-box-shadow-color);
  background-color: var(--md-editor-background-color);
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px 3px 0 0;
  -webkit-user-select: none;
          -moz-user-select: none;
       user-select: none;
  flex-wrap: wrap;
}
.w-md-editor-toolbar.bottom {
  border-bottom: 0px;
  border-top: 1px solid var(--md-editor-box-shadow-color);
  border-radius: 0 0 3px 3px;
}
.w-md-editor-toolbar ul,
.w-md-editor-toolbar li {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: initial;
}
.w-md-editor-toolbar li {
  display: inline-block;
  font-size: 14px;
}
.w-md-editor-toolbar li + li {
  margin: 0;
}
.w-md-editor-toolbar li > button {
  border: none;
  height: 20px;
  line-height: 14px;
  background: none;
  padding: 4px;
  margin: 0 1px;
  border-radius: 2px;
  text-transform: none;
  font-weight: normal;
  overflow: visible;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;
  color: var(--color-fg-default);
}
.w-md-editor-toolbar li > button:hover,
.w-md-editor-toolbar li > button:focus {
  background-color: var(--color-neutral-muted);
  color: var(--color-accent-fg);
}
.w-md-editor-toolbar li > button:active {
  background-color: var(--color-neutral-muted);
  color: var(--color-danger-fg);
}
.w-md-editor-toolbar li > button:disabled {
  color: var(--md-editor-box-shadow-color);
  cursor: not-allowed;
}
.w-md-editor-toolbar li > button:disabled:hover {
  background-color: transparent;
  color: var(--md-editor-box-shadow-color);
}
.w-md-editor-toolbar li.active > button {
  color: var(--color-accent-fg);
  background-color: var(--color-neutral-muted);
}
.w-md-editor-toolbar-divider {
  height: 14px;
  width: 1px;
  margin: -3px 3px 0 3px !important;
  vertical-align: middle;
  background-color: var(--md-editor-box-shadow-color);
}
.w-md-editor {
  text-align: left;
  border-radius: 3px;
  padding-bottom: 1px;
  position: relative;
  color: var(--color-fg-default);
  --md-editor-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --md-editor-background-color: var(--color-canvas-default, #ffffff);
  --md-editor-box-shadow-color: var(--color-border-default, #d0d7de);
  box-shadow: 0 0 0 1px var(--md-editor-box-shadow-color), 0 0 0 var(--md-editor-box-shadow-color), 0 1px 1px var(--md-editor-box-shadow-color);
  background-color: var(--md-editor-background-color);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
}
.w-md-editor.w-md-editor-rtl {
  direction: rtl !important;
  text-align: right !important;
}
.w-md-editor.w-md-editor-rtl .w-md-editor-preview {
  right: unset !important;
  left: 0;
  text-align: right !important;
  box-shadow: inset -1px 0 0 0 var(--md-editor-box-shadow-color);
}
.w-md-editor.w-md-editor-rtl .w-md-editor-text {
  text-align: right !important;
}
.w-md-editor-toolbar {
  height: -moz-fit-content;
  height: fit-content;
}
.w-md-editor-content {
  height: 100%;
  overflow: auto;
  position: relative;
  border-radius: 0 0 3px 0;
}
.w-md-editor .copied {
  display: none !important;
}
.w-md-editor-input {
  width: 50%;
  height: 100%;
}
.w-md-editor-text-pre > code {
  word-break: break-word !important;
  white-space: pre-wrap !important;
}
.w-md-editor-preview {
  width: 50%;
  box-sizing: border-box;
  box-shadow: inset 1px 0 0 0 var(--md-editor-box-shadow-color);
  position: absolute;
  padding: 10px 20px;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 0 5px 0;
  display: flex;
  flex-direction: column;
}
.w-md-editor-preview .anchor {
  display: none;
}
.w-md-editor-preview .contains-task-list li.task-list-item {
  list-style: none;
}
.w-md-editor-show-preview .w-md-editor-input {
  width: 0%;
  overflow: hidden;
  background-color: var(--md-editor-background-color);
}
.w-md-editor-show-preview .w-md-editor-preview {
  width: 100%;
  box-shadow: inset 0 0 0 0;
}
.w-md-editor-show-edit .w-md-editor-input {
  width: 100%;
}
.w-md-editor-show-edit .w-md-editor-preview {
  width: 0%;
  padding: 0;
}
.w-md-editor-fullscreen {
  overflow: hidden;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100% !important;
}
.w-md-editor-fullscreen .w-md-editor-content {
  height: 100%;
}

/* stylelint-disable font-family-no-missing-generic-family-keyword */
@font-face {
  font-family: 'KaTeX_AMS';
  src: url(/_next/static/media/KaTeX_AMS-Regular.a79f1c31.woff2) format('woff2'), url(/_next/static/media/KaTeX_AMS-Regular.1608a09b.woff) format('woff'), url(/_next/static/media/KaTeX_AMS-Regular.4aafdb68.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Caligraphic';
  src: url(/_next/static/media/KaTeX_Caligraphic-Bold.ec17d132.woff2) format('woff2'), url(/_next/static/media/KaTeX_Caligraphic-Bold.b6770918.woff) format('woff'), url(/_next/static/media/KaTeX_Caligraphic-Bold.cce5b8ec.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Caligraphic';
  src: url(/_next/static/media/KaTeX_Caligraphic-Regular.55fac258.woff2) format('woff2'), url(/_next/static/media/KaTeX_Caligraphic-Regular.dad44a7f.woff) format('woff'), url(/_next/static/media/KaTeX_Caligraphic-Regular.07ef19e7.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Fraktur';
  src: url(/_next/static/media/KaTeX_Fraktur-Bold.d42a5579.woff2) format('woff2'), url(/_next/static/media/KaTeX_Fraktur-Bold.9f256b85.woff) format('woff'), url(/_next/static/media/KaTeX_Fraktur-Bold.b18f59e1.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Fraktur';
  src: url(/_next/static/media/KaTeX_Fraktur-Regular.d3c882a6.woff2) format('woff2'), url(/_next/static/media/KaTeX_Fraktur-Regular.7c187121.woff) format('woff'), url(/_next/static/media/KaTeX_Fraktur-Regular.ed38e79f.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Main';
  src: url(/_next/static/media/KaTeX_Main-Bold.c3fb5ac2.woff2) format('woff2'), url(/_next/static/media/KaTeX_Main-Bold.d181c465.woff) format('woff'), url(/_next/static/media/KaTeX_Main-Bold.b74a1a8b.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Main';
  src: url(/_next/static/media/KaTeX_Main-BoldItalic.6f2bb1df.woff2) format('woff2'), url(/_next/static/media/KaTeX_Main-BoldItalic.e3f82f9d.woff) format('woff'), url(/_next/static/media/KaTeX_Main-BoldItalic.70d8b0a5.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'KaTeX_Main';
  src: url(/_next/static/media/KaTeX_Main-Italic.8916142b.woff2) format('woff2'), url(/_next/static/media/KaTeX_Main-Italic.9024d815.woff) format('woff'), url(/_next/static/media/KaTeX_Main-Italic.47373d1e.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'KaTeX_Main';
  src: url(/_next/static/media/KaTeX_Main-Regular.0462f03b.woff2) format('woff2'), url(/_next/static/media/KaTeX_Main-Regular.7f51fe03.woff) format('woff'), url(/_next/static/media/KaTeX_Main-Regular.b7f8fe9b.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Math';
  src: url(/_next/static/media/KaTeX_Math-BoldItalic.572d331f.woff2) format('woff2'), url(/_next/static/media/KaTeX_Math-BoldItalic.f1035d8d.woff) format('woff'), url(/_next/static/media/KaTeX_Math-BoldItalic.a879cf83.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'KaTeX_Math';
  src: url(/_next/static/media/KaTeX_Math-Italic.f28c23ac.woff2) format('woff2'), url(/_next/static/media/KaTeX_Math-Italic.5295ba48.woff) format('woff'), url(/_next/static/media/KaTeX_Math-Italic.939bc644.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'KaTeX_SansSerif';
  src: url(/_next/static/media/KaTeX_SansSerif-Bold.8c5b5494.woff2) format('woff2'), url(/_next/static/media/KaTeX_SansSerif-Bold.bf59d231.woff) format('woff'), url(/_next/static/media/KaTeX_SansSerif-Bold.94e1e8dc.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_SansSerif';
  src: url(/_next/static/media/KaTeX_SansSerif-Italic.3b1e59b3.woff2) format('woff2'), url(/_next/static/media/KaTeX_SansSerif-Italic.7c9bc82b.woff) format('woff'), url(/_next/static/media/KaTeX_SansSerif-Italic.b4c20c84.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'KaTeX_SansSerif';
  src: url(/_next/static/media/KaTeX_SansSerif-Regular.ba21ed5f.woff2) format('woff2'), url(/_next/static/media/KaTeX_SansSerif-Regular.74048478.woff) format('woff'), url(/_next/static/media/KaTeX_SansSerif-Regular.d4d7ba48.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Script';
  src: url(/_next/static/media/KaTeX_Script-Regular.03e9641d.woff2) format('woff2'), url(/_next/static/media/KaTeX_Script-Regular.07505710.woff) format('woff'), url(/_next/static/media/KaTeX_Script-Regular.fe9cbbe1.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Size1';
  src: url(/_next/static/media/KaTeX_Size1-Regular.eae34984.woff2) format('woff2'), url(/_next/static/media/KaTeX_Size1-Regular.e1e279cb.woff) format('woff'), url(/_next/static/media/KaTeX_Size1-Regular.fabc004a.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Size2';
  src: url(/_next/static/media/KaTeX_Size2-Regular.5916a24f.woff2) format('woff2'), url(/_next/static/media/KaTeX_Size2-Regular.57727022.woff) format('woff'), url(/_next/static/media/KaTeX_Size2-Regular.d6b476ec.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Size3';
  src: url(/_next/static/media/KaTeX_Size3-Regular.b4230e7e.woff2) format('woff2'), url(/_next/static/media/KaTeX_Size3-Regular.9acaf01c.woff) format('woff'), url(/_next/static/media/KaTeX_Size3-Regular.a144ef58.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Size4';
  src: url(/_next/static/media/KaTeX_Size4-Regular.10d95fd3.woff2) format('woff2'), url(/_next/static/media/KaTeX_Size4-Regular.7a996c9d.woff) format('woff'), url(/_next/static/media/KaTeX_Size4-Regular.fbccdabe.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KaTeX_Typewriter';
  src: url(/_next/static/media/KaTeX_Typewriter-Regular.a8709e36.woff2) format('woff2'), url(/_next/static/media/KaTeX_Typewriter-Regular.6258592b.woff) format('woff'), url(/_next/static/media/KaTeX_Typewriter-Regular.d97aaf4a.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
.katex {
  font: normal 1.21em KaTeX_Main, Times New Roman, serif;
  line-height: 1.2;
  text-indent: 0;
  text-rendering: auto;
}
.katex * {
  -ms-high-contrast-adjust: none !important;
}
.katex * {
  border-color: currentColor;
}
.katex .katex-version::after {
  content: "0.16.9";
}
.katex .katex-mathml {
  /* Accessibility hack to only show to screen readers
         Found at: http://a11yproject.com/posts/how-to-hide-content/ */
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.katex .katex-html {
  /* \newline is an empty block at top level, between .base elements */
}
.katex .katex-html > .newline {
  display: block;
}
.katex .base {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  width: min-content;
}
.katex .strut {
  display: inline-block;
}
.katex .textbf {
  font-weight: bold;
}
.katex .textit {
  font-style: italic;
}
.katex .textrm {
  font-family: KaTeX_Main;
}
.katex .textsf {
  font-family: KaTeX_SansSerif;
}
.katex .texttt {
  font-family: KaTeX_Typewriter;
}
.katex .mathnormal {
  font-family: KaTeX_Math;
  font-style: italic;
}
.katex .mathit {
  font-family: KaTeX_Main;
  font-style: italic;
}
.katex .mathrm {
  font-style: normal;
}
.katex .mathbf {
  font-family: KaTeX_Main;
  font-weight: bold;
}
.katex .boldsymbol {
  font-family: KaTeX_Math;
  font-weight: bold;
  font-style: italic;
}
.katex .amsrm {
  font-family: KaTeX_AMS;
}
.katex .mathbb,
.katex .textbb {
  font-family: KaTeX_AMS;
}
.katex .mathcal {
  font-family: KaTeX_Caligraphic;
}
.katex .mathfrak,
.katex .textfrak {
  font-family: KaTeX_Fraktur;
}
.katex .mathboldfrak,
.katex .textboldfrak {
  font-family: KaTeX_Fraktur;
  font-weight: bold;
}
.katex .mathtt {
  font-family: KaTeX_Typewriter;
}
.katex .mathscr,
.katex .textscr {
  font-family: KaTeX_Script;
}
.katex .mathsf,
.katex .textsf {
  font-family: KaTeX_SansSerif;
}
.katex .mathboldsf,
.katex .textboldsf {
  font-family: KaTeX_SansSerif;
  font-weight: bold;
}
.katex .mathitsf,
.katex .textitsf {
  font-family: KaTeX_SansSerif;
  font-style: italic;
}
.katex .mainrm {
  font-family: KaTeX_Main;
  font-style: normal;
}
.katex .vlist-t {
  display: inline-table;
  table-layout: fixed;
  border-collapse: collapse;
}
.katex .vlist-r {
  display: table-row;
}
.katex .vlist {
  display: table-cell;
  vertical-align: bottom;
  position: relative;
}
.katex .vlist > span {
  display: block;
  height: 0;
  position: relative;
}
.katex .vlist > span > span {
  display: inline-block;
}
.katex .vlist > span > .pstrut {
  overflow: hidden;
  width: 0;
}
.katex .vlist-t2 {
  margin-right: -2px;
}
.katex .vlist-s {
  display: table-cell;
  vertical-align: bottom;
  font-size: 1px;
  width: 2px;
  min-width: 2px;
}
.katex .vbox {
  display: inline-flex;
  flex-direction: column;
  align-items: baseline;
}
.katex .hbox {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}
.katex .thinbox {
  display: inline-flex;
  flex-direction: row;
  width: 0;
  max-width: 0;
}
.katex .msupsub {
  text-align: left;
}
.katex .mfrac > span > span {
  text-align: center;
}
.katex .mfrac .frac-line {
  display: inline-block;
  width: 100%;
  border-bottom-style: solid;
}
.katex .mfrac .frac-line,
.katex .overline .overline-line,
.katex .underline .underline-line,
.katex .hline,
.katex .hdashline,
.katex .rule {
  min-height: 1px;
}
.katex .mspace {
  display: inline-block;
}
.katex .llap,
.katex .rlap,
.katex .clap {
  width: 0;
  position: relative;
}
.katex .llap > .inner,
.katex .rlap > .inner,
.katex .clap > .inner {
  position: absolute;
}
.katex .llap > .fix,
.katex .rlap > .fix,
.katex .clap > .fix {
  display: inline-block;
}
.katex .llap > .inner {
  right: 0;
}
.katex .rlap > .inner,
.katex .clap > .inner {
  left: 0;
}
.katex .clap > .inner > span {
  margin-left: -50%;
  margin-right: 50%;
}
.katex .rule {
  display: inline-block;
  border: solid 0;
  position: relative;
}
.katex .overline .overline-line,
.katex .underline .underline-line,
.katex .hline {
  display: inline-block;
  width: 100%;
  border-bottom-style: solid;
}
.katex .hdashline {
  display: inline-block;
  width: 100%;
  border-bottom-style: dashed;
}
.katex .sqrt > .root {
  /* These values are taken from the definition of `\r@@t`,
             `\mkern 5mu` and `\mkern -10mu`. */
  margin-left: 0.27777778em;
  margin-right: -0.55555556em;
}
.katex .sizing.reset-size1.size1,
.katex .fontsize-ensurer.reset-size1.size1 {
  font-size: 1em;
}
.katex .sizing.reset-size1.size2,
.katex .fontsize-ensurer.reset-size1.size2 {
  font-size: 1.2em;
}
.katex .sizing.reset-size1.size3,
.katex .fontsize-ensurer.reset-size1.size3 {
  font-size: 1.4em;
}
.katex .sizing.reset-size1.size4,
.katex .fontsize-ensurer.reset-size1.size4 {
  font-size: 1.6em;
}
.katex .sizing.reset-size1.size5,
.katex .fontsize-ensurer.reset-size1.size5 {
  font-size: 1.8em;
}
.katex .sizing.reset-size1.size6,
.katex .fontsize-ensurer.reset-size1.size6 {
  font-size: 2em;
}
.katex .sizing.reset-size1.size7,
.katex .fontsize-ensurer.reset-size1.size7 {
  font-size: 2.4em;
}
.katex .sizing.reset-size1.size8,
.katex .fontsize-ensurer.reset-size1.size8 {
  font-size: 2.88em;
}
.katex .sizing.reset-size1.size9,
.katex .fontsize-ensurer.reset-size1.size9 {
  font-size: 3.456em;
}
.katex .sizing.reset-size1.size10,
.katex .fontsize-ensurer.reset-size1.size10 {
  font-size: 4.148em;
}
.katex .sizing.reset-size1.size11,
.katex .fontsize-ensurer.reset-size1.size11 {
  font-size: 4.976em;
}
.katex .sizing.reset-size2.size1,
.katex .fontsize-ensurer.reset-size2.size1 {
  font-size: 0.83333333em;
}
.katex .sizing.reset-size2.size2,
.katex .fontsize-ensurer.reset-size2.size2 {
  font-size: 1em;
}
.katex .sizing.reset-size2.size3,
.katex .fontsize-ensurer.reset-size2.size3 {
  font-size: 1.16666667em;
}
.katex .sizing.reset-size2.size4,
.katex .fontsize-ensurer.reset-size2.size4 {
  font-size: 1.33333333em;
}
.katex .sizing.reset-size2.size5,
.katex .fontsize-ensurer.reset-size2.size5 {
  font-size: 1.5em;
}
.katex .sizing.reset-size2.size6,
.katex .fontsize-ensurer.reset-size2.size6 {
  font-size: 1.66666667em;
}
.katex .sizing.reset-size2.size7,
.katex .fontsize-ensurer.reset-size2.size7 {
  font-size: 2em;
}
.katex .sizing.reset-size2.size8,
.katex .fontsize-ensurer.reset-size2.size8 {
  font-size: 2.4em;
}
.katex .sizing.reset-size2.size9,
.katex .fontsize-ensurer.reset-size2.size9 {
  font-size: 2.88em;
}
.katex .sizing.reset-size2.size10,
.katex .fontsize-ensurer.reset-size2.size10 {
  font-size: 3.45666667em;
}
.katex .sizing.reset-size2.size11,
.katex .fontsize-ensurer.reset-size2.size11 {
  font-size: 4.14666667em;
}
.katex .sizing.reset-size3.size1,
.katex .fontsize-ensurer.reset-size3.size1 {
  font-size: 0.71428571em;
}
.katex .sizing.reset-size3.size2,
.katex .fontsize-ensurer.reset-size3.size2 {
  font-size: 0.85714286em;
}
.katex .sizing.reset-size3.size3,
.katex .fontsize-ensurer.reset-size3.size3 {
  font-size: 1em;
}
.katex .sizing.reset-size3.size4,
.katex .fontsize-ensurer.reset-size3.size4 {
  font-size: 1.14285714em;
}
.katex .sizing.reset-size3.size5,
.katex .fontsize-ensurer.reset-size3.size5 {
  font-size: 1.28571429em;
}
.katex .sizing.reset-size3.size6,
.katex .fontsize-ensurer.reset-size3.size6 {
  font-size: 1.42857143em;
}
.katex .sizing.reset-size3.size7,
.katex .fontsize-ensurer.reset-size3.size7 {
  font-size: 1.71428571em;
}
.katex .sizing.reset-size3.size8,
.katex .fontsize-ensurer.reset-size3.size8 {
  font-size: 2.05714286em;
}
.katex .sizing.reset-size3.size9,
.katex .fontsize-ensurer.reset-size3.size9 {
  font-size: 2.46857143em;
}
.katex .sizing.reset-size3.size10,
.katex .fontsize-ensurer.reset-size3.size10 {
  font-size: 2.96285714em;
}
.katex .sizing.reset-size3.size11,
.katex .fontsize-ensurer.reset-size3.size11 {
  font-size: 3.55428571em;
}
.katex .sizing.reset-size4.size1,
.katex .fontsize-ensurer.reset-size4.size1 {
  font-size: 0.625em;
}
.katex .sizing.reset-size4.size2,
.katex .fontsize-ensurer.reset-size4.size2 {
  font-size: 0.75em;
}
.katex .sizing.reset-size4.size3,
.katex .fontsize-ensurer.reset-size4.size3 {
  font-size: 0.875em;
}
.katex .sizing.reset-size4.size4,
.katex .fontsize-ensurer.reset-size4.size4 {
  font-size: 1em;
}
.katex .sizing.reset-size4.size5,
.katex .fontsize-ensurer.reset-size4.size5 {
  font-size: 1.125em;
}
.katex .sizing.reset-size4.size6,
.katex .fontsize-ensurer.reset-size4.size6 {
  font-size: 1.25em;
}
.katex .sizing.reset-size4.size7,
.katex .fontsize-ensurer.reset-size4.size7 {
  font-size: 1.5em;
}
.katex .sizing.reset-size4.size8,
.katex .fontsize-ensurer.reset-size4.size8 {
  font-size: 1.8em;
}
.katex .sizing.reset-size4.size9,
.katex .fontsize-ensurer.reset-size4.size9 {
  font-size: 2.16em;
}
.katex .sizing.reset-size4.size10,
.katex .fontsize-ensurer.reset-size4.size10 {
  font-size: 2.5925em;
}
.katex .sizing.reset-size4.size11,
.katex .fontsize-ensurer.reset-size4.size11 {
  font-size: 3.11em;
}
.katex .sizing.reset-size5.size1,
.katex .fontsize-ensurer.reset-size5.size1 {
  font-size: 0.55555556em;
}
.katex .sizing.reset-size5.size2,
.katex .fontsize-ensurer.reset-size5.size2 {
  font-size: 0.66666667em;
}
.katex .sizing.reset-size5.size3,
.katex .fontsize-ensurer.reset-size5.size3 {
  font-size: 0.77777778em;
}
.katex .sizing.reset-size5.size4,
.katex .fontsize-ensurer.reset-size5.size4 {
  font-size: 0.88888889em;
}
.katex .sizing.reset-size5.size5,
.katex .fontsize-ensurer.reset-size5.size5 {
  font-size: 1em;
}
.katex .sizing.reset-size5.size6,
.katex .fontsize-ensurer.reset-size5.size6 {
  font-size: 1.11111111em;
}
.katex .sizing.reset-size5.size7,
.katex .fontsize-ensurer.reset-size5.size7 {
  font-size: 1.33333333em;
}
.katex .sizing.reset-size5.size8,
.katex .fontsize-ensurer.reset-size5.size8 {
  font-size: 1.6em;
}
.katex .sizing.reset-size5.size9,
.katex .fontsize-ensurer.reset-size5.size9 {
  font-size: 1.92em;
}
.katex .sizing.reset-size5.size10,
.katex .fontsize-ensurer.reset-size5.size10 {
  font-size: 2.30444444em;
}
.katex .sizing.reset-size5.size11,
.katex .fontsize-ensurer.reset-size5.size11 {
  font-size: 2.76444444em;
}
.katex .sizing.reset-size6.size1,
.katex .fontsize-ensurer.reset-size6.size1 {
  font-size: 0.5em;
}
.katex .sizing.reset-size6.size2,
.katex .fontsize-ensurer.reset-size6.size2 {
  font-size: 0.6em;
}
.katex .sizing.reset-size6.size3,
.katex .fontsize-ensurer.reset-size6.size3 {
  font-size: 0.7em;
}
.katex .sizing.reset-size6.size4,
.katex .fontsize-ensurer.reset-size6.size4 {
  font-size: 0.8em;
}
.katex .sizing.reset-size6.size5,
.katex .fontsize-ensurer.reset-size6.size5 {
  font-size: 0.9em;
}
.katex .sizing.reset-size6.size6,
.katex .fontsize-ensurer.reset-size6.size6 {
  font-size: 1em;
}
.katex .sizing.reset-size6.size7,
.katex .fontsize-ensurer.reset-size6.size7 {
  font-size: 1.2em;
}
.katex .sizing.reset-size6.size8,
.katex .fontsize-ensurer.reset-size6.size8 {
  font-size: 1.44em;
}
.katex .sizing.reset-size6.size9,
.katex .fontsize-ensurer.reset-size6.size9 {
  font-size: 1.728em;
}
.katex .sizing.reset-size6.size10,
.katex .fontsize-ensurer.reset-size6.size10 {
  font-size: 2.074em;
}
.katex .sizing.reset-size6.size11,
.katex .fontsize-ensurer.reset-size6.size11 {
  font-size: 2.488em;
}
.katex .sizing.reset-size7.size1,
.katex .fontsize-ensurer.reset-size7.size1 {
  font-size: 0.41666667em;
}
.katex .sizing.reset-size7.size2,
.katex .fontsize-ensurer.reset-size7.size2 {
  font-size: 0.5em;
}
.katex .sizing.reset-size7.size3,
.katex .fontsize-ensurer.reset-size7.size3 {
  font-size: 0.58333333em;
}
.katex .sizing.reset-size7.size4,
.katex .fontsize-ensurer.reset-size7.size4 {
  font-size: 0.66666667em;
}
.katex .sizing.reset-size7.size5,
.katex .fontsize-ensurer.reset-size7.size5 {
  font-size: 0.75em;
}
.katex .sizing.reset-size7.size6,
.katex .fontsize-ensurer.reset-size7.size6 {
  font-size: 0.83333333em;
}
.katex .sizing.reset-size7.size7,
.katex .fontsize-ensurer.reset-size7.size7 {
  font-size: 1em;
}
.katex .sizing.reset-size7.size8,
.katex .fontsize-ensurer.reset-size7.size8 {
  font-size: 1.2em;
}
.katex .sizing.reset-size7.size9,
.katex .fontsize-ensurer.reset-size7.size9 {
  font-size: 1.44em;
}
.katex .sizing.reset-size7.size10,
.katex .fontsize-ensurer.reset-size7.size10 {
  font-size: 1.72833333em;
}
.katex .sizing.reset-size7.size11,
.katex .fontsize-ensurer.reset-size7.size11 {
  font-size: 2.07333333em;
}
.katex .sizing.reset-size8.size1,
.katex .fontsize-ensurer.reset-size8.size1 {
  font-size: 0.34722222em;
}
.katex .sizing.reset-size8.size2,
.katex .fontsize-ensurer.reset-size8.size2 {
  font-size: 0.41666667em;
}
.katex .sizing.reset-size8.size3,
.katex .fontsize-ensurer.reset-size8.size3 {
  font-size: 0.48611111em;
}
.katex .sizing.reset-size8.size4,
.katex .fontsize-ensurer.reset-size8.size4 {
  font-size: 0.55555556em;
}
.katex .sizing.reset-size8.size5,
.katex .fontsize-ensurer.reset-size8.size5 {
  font-size: 0.625em;
}
.katex .sizing.reset-size8.size6,
.katex .fontsize-ensurer.reset-size8.size6 {
  font-size: 0.69444444em;
}
.katex .sizing.reset-size8.size7,
.katex .fontsize-ensurer.reset-size8.size7 {
  font-size: 0.83333333em;
}
.katex .sizing.reset-size8.size8,
.katex .fontsize-ensurer.reset-size8.size8 {
  font-size: 1em;
}
.katex .sizing.reset-size8.size9,
.katex .fontsize-ensurer.reset-size8.size9 {
  font-size: 1.2em;
}
.katex .sizing.reset-size8.size10,
.katex .fontsize-ensurer.reset-size8.size10 {
  font-size: 1.44027778em;
}
.katex .sizing.reset-size8.size11,
.katex .fontsize-ensurer.reset-size8.size11 {
  font-size: 1.72777778em;
}
.katex .sizing.reset-size9.size1,
.katex .fontsize-ensurer.reset-size9.size1 {
  font-size: 0.28935185em;
}
.katex .sizing.reset-size9.size2,
.katex .fontsize-ensurer.reset-size9.size2 {
  font-size: 0.34722222em;
}
.katex .sizing.reset-size9.size3,
.katex .fontsize-ensurer.reset-size9.size3 {
  font-size: 0.40509259em;
}
.katex .sizing.reset-size9.size4,
.katex .fontsize-ensurer.reset-size9.size4 {
  font-size: 0.46296296em;
}
.katex .sizing.reset-size9.size5,
.katex .fontsize-ensurer.reset-size9.size5 {
  font-size: 0.52083333em;
}
.katex .sizing.reset-size9.size6,
.katex .fontsize-ensurer.reset-size9.size6 {
  font-size: 0.5787037em;
}
.katex .sizing.reset-size9.size7,
.katex .fontsize-ensurer.reset-size9.size7 {
  font-size: 0.69444444em;
}
.katex .sizing.reset-size9.size8,
.katex .fontsize-ensurer.reset-size9.size8 {
  font-size: 0.83333333em;
}
.katex .sizing.reset-size9.size9,
.katex .fontsize-ensurer.reset-size9.size9 {
  font-size: 1em;
}
.katex .sizing.reset-size9.size10,
.katex .fontsize-ensurer.reset-size9.size10 {
  font-size: 1.20023148em;
}
.katex .sizing.reset-size9.size11,
.katex .fontsize-ensurer.reset-size9.size11 {
  font-size: 1.43981481em;
}
.katex .sizing.reset-size10.size1,
.katex .fontsize-ensurer.reset-size10.size1 {
  font-size: 0.24108004em;
}
.katex .sizing.reset-size10.size2,
.katex .fontsize-ensurer.reset-size10.size2 {
  font-size: 0.28929605em;
}
.katex .sizing.reset-size10.size3,
.katex .fontsize-ensurer.reset-size10.size3 {
  font-size: 0.33751205em;
}
.katex .sizing.reset-size10.size4,
.katex .fontsize-ensurer.reset-size10.size4 {
  font-size: 0.38572806em;
}
.katex .sizing.reset-size10.size5,
.katex .fontsize-ensurer.reset-size10.size5 {
  font-size: 0.43394407em;
}
.katex .sizing.reset-size10.size6,
.katex .fontsize-ensurer.reset-size10.size6 {
  font-size: 0.48216008em;
}
.katex .sizing.reset-size10.size7,
.katex .fontsize-ensurer.reset-size10.size7 {
  font-size: 0.57859209em;
}
.katex .sizing.reset-size10.size8,
.katex .fontsize-ensurer.reset-size10.size8 {
  font-size: 0.69431051em;
}
.katex .sizing.reset-size10.size9,
.katex .fontsize-ensurer.reset-size10.size9 {
  font-size: 0.83317261em;
}
.katex .sizing.reset-size10.size10,
.katex .fontsize-ensurer.reset-size10.size10 {
  font-size: 1em;
}
.katex .sizing.reset-size10.size11,
.katex .fontsize-ensurer.reset-size10.size11 {
  font-size: 1.19961427em;
}
.katex .sizing.reset-size11.size1,
.katex .fontsize-ensurer.reset-size11.size1 {
  font-size: 0.20096463em;
}
.katex .sizing.reset-size11.size2,
.katex .fontsize-ensurer.reset-size11.size2 {
  font-size: 0.24115756em;
}
.katex .sizing.reset-size11.size3,
.katex .fontsize-ensurer.reset-size11.size3 {
  font-size: 0.28135048em;
}
.katex .sizing.reset-size11.size4,
.katex .fontsize-ensurer.reset-size11.size4 {
  font-size: 0.32154341em;
}
.katex .sizing.reset-size11.size5,
.katex .fontsize-ensurer.reset-size11.size5 {
  font-size: 0.36173633em;
}
.katex .sizing.reset-size11.size6,
.katex .fontsize-ensurer.reset-size11.size6 {
  font-size: 0.40192926em;
}
.katex .sizing.reset-size11.size7,
.katex .fontsize-ensurer.reset-size11.size7 {
  font-size: 0.48231511em;
}
.katex .sizing.reset-size11.size8,
.katex .fontsize-ensurer.reset-size11.size8 {
  font-size: 0.57877814em;
}
.katex .sizing.reset-size11.size9,
.katex .fontsize-ensurer.reset-size11.size9 {
  font-size: 0.69453376em;
}
.katex .sizing.reset-size11.size10,
.katex .fontsize-ensurer.reset-size11.size10 {
  font-size: 0.83360129em;
}
.katex .sizing.reset-size11.size11,
.katex .fontsize-ensurer.reset-size11.size11 {
  font-size: 1em;
}
.katex .delimsizing.size1 {
  font-family: KaTeX_Size1;
}
.katex .delimsizing.size2 {
  font-family: KaTeX_Size2;
}
.katex .delimsizing.size3 {
  font-family: KaTeX_Size3;
}
.katex .delimsizing.size4 {
  font-family: KaTeX_Size4;
}
.katex .delimsizing.mult .delim-size1 > span {
  font-family: KaTeX_Size1;
}
.katex .delimsizing.mult .delim-size4 > span {
  font-family: KaTeX_Size4;
}
.katex .nulldelimiter {
  display: inline-block;
  width: 0.12em;
}
.katex .delimcenter {
  position: relative;
}
.katex .op-symbol {
  position: relative;
}
.katex .op-symbol.small-op {
  font-family: KaTeX_Size1;
}
.katex .op-symbol.large-op {
  font-family: KaTeX_Size2;
}
.katex .op-limits > .vlist-t {
  text-align: center;
}
.katex .accent > .vlist-t {
  text-align: center;
}
.katex .accent .accent-body {
  position: relative;
}
.katex .accent .accent-body:not(.accent-full) {
  width: 0;
}
.katex .overlay {
  display: block;
}
.katex .mtable .vertical-separator {
  display: inline-block;
  min-width: 1px;
}
.katex .mtable .arraycolsep {
  display: inline-block;
}
.katex .mtable .col-align-c > .vlist-t {
  text-align: center;
}
.katex .mtable .col-align-l > .vlist-t {
  text-align: left;
}
.katex .mtable .col-align-r > .vlist-t {
  text-align: right;
}
.katex .svg-align {
  text-align: left;
}
.katex svg {
  display: block;
  position: absolute;
  width: 100%;
  height: inherit;
  fill: currentColor;
  stroke: currentColor;
  fill-rule: nonzero;
  fill-opacity: 1;
  stroke-width: 1;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-dashoffset: 0;
  stroke-opacity: 1;
}
.katex svg path {
  stroke: none;
}
.katex img {
  border-style: none;
  min-width: 0;
  min-height: 0;
  max-width: none;
  max-height: none;
}
.katex .stretchy {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}
.katex .stretchy::before,
.katex .stretchy::after {
  content: "";
}
.katex .hide-tail {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.katex .halfarrow-left {
  position: absolute;
  left: 0;
  width: 50.2%;
  overflow: hidden;
}
.katex .halfarrow-right {
  position: absolute;
  right: 0;
  width: 50.2%;
  overflow: hidden;
}
.katex .brace-left {
  position: absolute;
  left: 0;
  width: 25.1%;
  overflow: hidden;
}
.katex .brace-center {
  position: absolute;
  left: 25%;
  width: 50%;
  overflow: hidden;
}
.katex .brace-right {
  position: absolute;
  right: 0;
  width: 25.1%;
  overflow: hidden;
}
.katex .x-arrow-pad {
  padding: 0 0.5em;
}
.katex .cd-arrow-pad {
  padding: 0 0.55556em 0 0.27778em;
}
.katex .x-arrow,
.katex .mover,
.katex .munder {
  text-align: center;
}
.katex .boxpad {
  padding: 0 0.3em;
}
.katex .fbox,
.katex .fcolorbox {
  box-sizing: border-box;
  border: 0.04em solid;
}
.katex .cancel-pad {
  padding: 0 0.2em;
}
.katex .cancel-lap {
  margin-left: -0.2em;
  margin-right: -0.2em;
}
.katex .sout {
  border-bottom-style: solid;
  border-bottom-width: 0.08em;
}
.katex .angl {
  box-sizing: border-box;
  border-top: 0.049em solid;
  border-right: 0.049em solid;
  margin-right: 0.03889em;
}
.katex .anglpad {
  padding: 0 0.03889em;
}
.katex .eqn-num::before {
  counter-increment: katexEqnNo;
  content: "(" counter(katexEqnNo) ")";
}
.katex .mml-eqn-num::before {
  counter-increment: mmlEqnNo;
  content: "(" counter(mmlEqnNo) ")";
}
.katex .mtr-glue {
  width: 50%;
}
.katex .cd-vert-arrow {
  display: inline-block;
  position: relative;
}
.katex .cd-label-left {
  display: inline-block;
  position: absolute;
  right: calc(50% + 0.3em);
  text-align: left;
}
.katex .cd-label-right {
  display: inline-block;
  position: absolute;
  left: calc(50% + 0.3em);
  text-align: right;
}
.katex-display {
  display: block;
  margin: 1em 0;
  text-align: center;
}
.katex-display > .katex {
  display: block;
  text-align: center;
  white-space: nowrap;
}
.katex-display > .katex > .katex-html {
  display: block;
  position: relative;
}
.katex-display > .katex > .katex-html > .tag {
  position: absolute;
  right: 0;
}
.katex-display.leqno > .katex > .katex-html > .tag {
  left: 0;
  right: auto;
}
.katex-display.fleqn > .katex {
  text-align: left;
  padding-left: 2em;
}
body {
  counter-reset: katexEqnNo mmlEqnNo;
}


